import * as React from "react"
import '../../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../../fonts/font-awesome/css/font-awesome.min.css";
import "../../css/template.css";
import "../../css/responsive.css";
import "../../css/base-sizing.css";
import "../../css/custom.css";
import "../../css/knowledge.css";

import HeaderAndMenu from "../../page_components/common/HeaderAndMenu";
import Footer from "../../page_components/common/Footer";
import BsHelmet from "../../page_components/common/BsHelmet";
import CommonHeroLow from "../../page_components/common/CommonHeroLow";
import BreadCrumbs from "./breadcrumbs";
import {StaticImage} from "gatsby-plugin-image";
import {useEffect} from "react";
import initStickyMenu from "../../page_components/common/initStickyMenu";


const Fst = () => {

    useEffect(() => {
        initStickyMenu();
    }, []);


    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"BitSpreader Documentation - Futures Spread Trading Terminal"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHeroLow title={"BitSpreader Documentation - Futures Spread Trading Terminal"}/>
                <BreadCrumbs link={"fst"} title="Futures Spread Trading Terminal"/>
                <section className="hg_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div id="stickymenu" className="sidebar knowledgebase">
                                    <div className="widget widget_categories">
                                        <h3 className="widgettitle title">SECTIONS</h3>
                                        <a href="#marketselector">Spread Market Selector</a>
                                        <ul className="menu">
                                            <li><a href="#step1">Step 1: Futures type selection</a></li>
                                            <li><a href="#step2">Step 2: Exchange and instrument selection</a></li>
                                            <li><a href="#instrumentdetails">Instrument details</a></li>
                                        </ul>
                                        <a href="#spreadmatrix">Spread Matrix</a>
                                        <a href="#spreadchart">Spread Chart</a>
                                        <ul className="menu">
                                            <li><a href="#chart_howisbuilt">How the spread chart is built?</a></li>
                                            <li><a href="#chart_pricetypes">Price type selection</a></li>
                                        </ul>
                                        <a href="#orderbook">Order Book</a>
                                        <a href="#tradingpanel">Trading Panel</a>
                                        <ul className="menu">
                                            <li><a href="#marketorder">Market Order</a></li>
                                            <li><a href="#autotrigger">Auto-Trigger Order</a></li>
                                            <li><a href="#autospreading">Auto-Spreading Order</a></li>
                                            <li><a href="#spreadmarketdetails">Spread Market Details</a></li>
                                        </ul>
                                        <a href="#activespreadorders">Active Spread Orders</a>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-8"}>
                                <h2 id="stickyref" className="kb-article--title">
                                    <a name={"fst"}>Futures Spread Trading Terminal</a>
                                </h2>
                                <div className={"row"}>
                                    <div className={"col-md-12 pt-10"}>
                                        <StaticImage src={"../../gatsbyimg/docs/fst.jpeg"} alt={"Crypto Futures Spread Trading Terminal"} className={"border"}/>
                                    </div>
                                </div>

                                <br/><br/>
                                <a name={"marketselector"}><h4 className={"fw-bold"}>Spread Market Selector</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        Spread market selector drives the spread that is currently displayed on the spread chart as well as on the orderbook. Also all the orders will be executed
                                        against the currently selected spread.

                                        <br/><br/>Currently BitSpreader only allows for trading on spreads constructed from two futures.
                                        <ul className="list-style1">
                                            <li>These futures need to share the same underlying instrument</li>
                                            <li>These futures need to share the same margin type (USD or coin margined)</li>
                                        </ul>

                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/marketselector.png"} alt={"Spread market selector"} className={"border"}/>
                                    </div>
                                </div>
                                <a name={"step1"}><h4 className={"pt-20 fw-bold"}>Step 1: Futures type selection</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        Select one of:
                                        <br/>
                                        <ul className="list-style1">
                                            <li>Coin margined futures (inverse) - available on Deribit and Binance and Huobi</li>
                                            <li>USD margined futures - available on Deribit and Binance</li>
                                        </ul>
                                        <br/>
                                        <b>Coin margined</b> futures use base currency of the instrument as the currency in which margin is controlled
                                        <br/>
                                        <b>USD margined</b> futures use stable coins as the currency in which margin is controlled

                                    </div>
                                    <div className={'col-md-6'}>
                                    </div>
                                </div>
                                <a name={"step2"}><h4 className={"pt-20 fw-bold"}>Step 2: exchange and instrument selection</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        You can construct spreads from instruments across two futures on the same exchange or across two futures on two different exchanges.
                                        Exchange selector always shows exchanges containing futures of type selected in "Futures type" selector. It is not possible to select same instrument in both
                                        legs.
                                        After selecting a spread, chart, orderbook and deal ticket show the data relating to the selected spread.
                                        <br/><br/>
                                        The spread will be constructed as a price difference between LEG1 and LEG2: <b>LEG1 - LEG2</b>.
                                        That means that:
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li><b>buying the spread</b>: we are buying LEG1 and selling LEG2</li>
                                            <li><b>selling the spread</b>: we are selling LEG1 and buying LEG2</li>
                                        </ul>
                                        <br/><br/>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/nearfar.png"} alt={"Spread market selector"} className={"border"}/>
                                    </div>
                                </div>
                                <a name={"instrumentdetails"}><h4 className={"pt-20 fw-bold"}>Instrument details</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        Beneath each spread leg after moving your mouse pointer over the information icon you can view the details of the instrument that is selected

                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/instrument_details.png"} alt={"Spread instrument selector"} className={"border pt-10"}/>
                                    </div>
                                </div>

                                <div className={"row pt-20"}>
                                    <div className={'col-md-12'}>
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '30%'}}>Field</th>
                                                <th style={{width: '70%'}}>Comments</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th style={{width: '30%'}}>Amount step size</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Amount step size is the minimum increment allowed when buying or selling this instrument
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>Contract size</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Contract size refers to the amount or quantity of an underlying security represented by a derivatives contract usually equal to amount step size.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>Minimum notional value</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Minimum notional value expresses minimum value of the contract, below which the exchange will reject the order
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>Minimal trade size</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Minimum trade size expressed in base contract currency
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>Max leverage</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Maximum leverage available for this instrument on exchange
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>Default leverage</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Leverage set by default. <b>In order to change the default leverage, refer to the exchange specific settings <a href={"/docs/apikeys/#leverage"}
                                                                                                                                                                    className={"read-more-link"}>here</a></b>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <a name={"spreadmatrix"}><h4 className={"pt-20 fw-bold"}>Spread Matrix</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12'}>
                                        <StaticImage src={"../../gatsbyimg/screenshots/SpreadMatrix3.png"} alt={"Spread instrument matrix selector"} className={"border pt-10"}/>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify pt-10'}>
                                        <p>
                                            Spread Matrix streams current values of cross-exchange and intra-exchange spreads. You can choose any combination of exchanges, LEG1 instruments will be on
                                            vertical axis,
                                            LEG2 instruments will be on horizontal axis. On crossings you will find the spreads between particular pairs of instruments.
                                        </p>
                                        <p>
                                            Spread Matrix is also an alternative way of selecting the spread on which you want to trade - just click on selected spread, it will be chosen as the
                                            current instrument for trade
                                        </p>

                                    </div>
                                </div>
                                <a name={"spreadchart"}><h4 className={"pt-20 fw-bold"}>Spread Chart</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 pt-20'}>
                                        <StaticImage src={"../../gatsbyimg/docs/spreadchart.png"} alt={"Spread chart"} className={"border pt-10"}/>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify pt-20'}>

                                        BitSpreader uses standard Trading View javascript library for displaying spread candles. You can find basic tutorial and help to the chart legend and
                                        indicators&nbsp;
                                        <a href="https://www.tradingview.com/support/categories/chart/"><b>HERE</b></a>
                                        <br/><br/>

                                        <a name={"chart_howisbuilt"}><h4>How the spread chart is built?</h4></a>
                                        <b>BitSpreader builds the data set represented on the chart based on the real time ticker data streamed from exchanges. We are not using TradingView data. We
                                            are not subtracting one candles set from another.</b>

                                        <br/><br/><b>Here is how we do it:</b><br/>
                                        <ul className="list-style1">
                                            <li>BitSpreader generates synthetic spread instruments - each spread instrument consists of two legs - two futures markets - that have the same underlying
                                                instrument (ie BTC) and are of same futures type (coin margined or usd margined).
                                                BitSpreader creates all possible combinations of pairs- each available market with every other market.
                                            </li>

                                            <li>BitSpreader subscribes to all the derivative markets on all exchanges supported by BitSpreader and constantly receives stream of updates on orderbooks,
                                                trades and tickers.
                                            </li>
                                            <li>Every time the ticker arrives from any futures market, it updates all the spread instruments containing this futures market</li>
                                        </ul>
                                        <b>This method is much more precise than the method used by majority of the tools on market.</b> Majority of the tools capable of showing the spreads just
                                        subtracts two sets of candles. Each candle is set of four coordinates - <b>Open, High, Low, Close</b>.
                                        <br/>When subtracting two data sets in this form Open and Close are calculated properly - we can subtract them from each other as they have exactly same time
                                        coordinates, but we are getting invalid highs and lows - we subtract values that have different time coordinates, thus the result is invalid.
                                        <br/><b>Conclusion is: BitSpreader chart is much more precise and better tool for analysis than other tools that built the spread chart by subtracting two sets
                                        of candles</b>
                                    </div>
                                </div>
                                <a name={"chart_pricetypes"}><h4 className={"pt-20"}>Price typeselection</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12'}>
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '30%'}}>Price Type</th>
                                                <th style={{width: '70%'}}>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th style={{width: '30%'}}>LAST price</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Difference between the prices of last trades on each of the spread legs
                                                    <br/><br/><i><b>LastPrice = Leg1LastPrice-Leg2LastPrice</b></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>ASK price</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    The price for which you can BUY given spread. In other words it is ASK spread price. It is equal to the lowest entry in the spread order book on the
                                                    ASK side.
                                                    <br/><br/><i><b>BuyPrice = Leg1AskPrice-Leg2BidPrice</b></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>BID price</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    The price for which you can SELL given spread. In other words it is BID spread price. It is equal to the highest entry in the spread order book on
                                                    the BID side.
                                                    <br/><br/><i><b>SellPrice = Leg1BidPrice-Leg2AskPrice</b></i>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <br/><br/>
                                <a name={"orderbook"}><h4 className={"pt-20 fw-bold"}>Spread Order Book</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-9 text-justify'}>
                                        Spread Order Book represents synthetic order book generated in real time by BitSpreader.
                                        <br/><br/><b>Here is how we do it:</b><br/>
                                        <ul className="list-style1">
                                            <li>BitSpreader generates synthetic spread instruments - each spread instrument consists of two legs - two futures markets - that have the same underlying
                                                instrument (ie BTC) and are of same futures type (coin margined or usd margined).
                                                BitSpreader creates all possible combinations of pairs- each available market with every other market.
                                            </li>

                                            <li>BitSpreader subscribes to all the derivative markets on all exchanges supported by BitSpreader and constantly receives stream of updates on orderbooks,
                                                trades and tickers.
                                            </li>
                                            <li>Every time the orderbook update arrives from any futures market, it updates all the spread order books containing this futures market</li>
                                            <li>Spread order book is a result of <b>composing two order books into one order book</b>.
                                                <br/><br/>The ASK side of Spread Order Book is a result of matching the ASK side of order book of Leg 1 futures market with the BID side of orderbook of
                                                Leg 2 futures market.
                                                <br/><br/>The BID side of Spread Order Book is a result of matching the BID side of order book of Leg 1 futures market with the ASK side of orderbook of
                                                Leg 2 futures market.

                                                <br/><br/>Detailed explanation of the mechanism will be the subject for the separate article.

                                            </li>
                                        </ul>
                                    </div>
                                    <div className={'col-md-3 text-justify'}>
                                        <StaticImage src={"../../gatsbyimg/docs/orderbook.png"} alt={"Spread Order Book"} className={"border pt-10"}/>
                                    </div>
                                </div>
                                <br/><br/>
                                <a name={"tradingpanel"}><h4 className={"pt-20 fw-bold"}>Trading Panel</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        BitSpreader currently supports following order types:
                                        <br/><br/>
                                        <ul className="list-style1 pt-10">
                                            <li>Market order</li>
                                            <li>Auto-Trigger order</li>
                                            <li>Auto-Spreading order</li>
                                        </ul>
                                        <br/><br/>
                                    </div>
                                </div>
                                <a name={"marketorder"}><h4 className={"pt-20 fw-bold"}>Market Order</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <ul className="list-style1 pt-10">
                                            <li><b>size</b> - size of each outright contract</li>
                                        </ul>
                                        Spread Market Order is realised by two market orders issued by BitSpreader to the exchanges. Both market orders are sent with the same size - provided by user,
                                        but with different directions.
                                    </div>
                                    <div className={'col-md-6 text-justify'}>
                                        <StaticImage src={"../../gatsbyimg/docs/tradingpanel.png"} alt={"Spread Trading Panel"} className={"border pt-10"}/>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <ul className="list-style1 pt-20">
                                            <li><b>BUY</b> spread means: BUY LEG 1, SELL LEG 2</li>
                                            <li><b>SELL</b> spread means: SELL LEG 1, BUY LEG 2</li>
                                        </ul>
                                        <br/><br/>
                                        <b>IMPORTANT!</b> There is no guarantee that the actual transaction price will be the price currently displayed on the BitSpreader Futures Spread Trading
                                        Terminal.
                                        The market moves very fast and there is always a chance that the order books will be in different state when your market orders get to the exchange.


                                        <br/><br/><b>Restrictions and Validation: </b> please refer to the <a href="#spreaedmarketdetails" className={"read-more-link"}>Spread Market
                                        Details</a> section for reference

                                    </div>
                                </div>
                                <br/><br/>
                                <a name={"autotrigger"}><h4 className={"pt-20 fw-bold"}>Auto-Trigger Order</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <ul className="list-style1 pt-10">
                                            <li><b>trigger price</b> - price at which the order will be triggered</li>
                                            <li><b>size</b> - size of each outright contract</li>
                                        </ul>
                                        <br/><br/>
                                    </div>
                                    <div className={'col-md-6 text-justify'}>
                                        <StaticImage src={"../../gatsbyimg/docs/autotrigger.png"} alt={"Auto trigger order"} className={"border pt-10"}/>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <p>
                                            Auto-Trigger orders are executed by BitSpreader when the price reaches the trigger price level. At that moment BitSpreader executes regular <a
                                            href={"#marketorder"} className={"read_more_link"}>Market Order</a> with provided size.
                                            <br/><br/>
                                            <ul className="list-style1 pt-10">
                                                <li>Auto-Trigger <b>BUY</b> - BitSpreader will execute the order when the spread ASK price is below the trigger price</li>
                                                <li>Auto-Trigger <b>SELL</b> - BitSpreader will execute the order when the spread BID price is above the trigger price</li>
                                            </ul>
                                            <br/><br/>
                                        </p>
                                        <p>Auto-Trigger order works in a similar way as a limit order on regular exchange but the differences are significant, therefore we avoid using the name Limit
                                            Order in this case.</p>

                                        <br/>
                                        <b>IMPORTANT!</b> There is no guarantee that the actual price of the transaction will be equal to the trigger price - there is high probability
                                        of <b>SLIPPAGE</b> due to the <b>LATENCY</b>.
                                        Auto-Trigger uses market orders for the execution. There is a latency introduced on transport between the exchange and BitSpreader.
                                        The market moves very fast and there is high chance that the order books will be in different state when your market orders get to the exchange.

                                        <br/><br/><b>After the Auto-Trigger order is created: </b> you can manage the order in the <a href="#activespreadorders" className={"read-more-link"}>Active
                                        Spread Orders</a> pane


                                        <br/><br/><b>Restrictions and Validation: </b> please refer to the <a href="#spreaedmarketdetails" className={"read-more-link"}>Spread Market
                                        Details</a> section for reference

                                    </div>

                                </div>

                                <br/><br/><a name={"autospreading"}><h4 className={"pt-20 fw-bold"}>Auto-Spreading Order</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <h3>Basic options</h3>
                                        <ul className="list-style1 pt-10">
                                            <li><b>Spread limit price</b> - limit price of the spread</li>
                                            <li><b>Size</b> - size of each outright contract</li>
                                        </ul>
                                        <br/><br/>
                                    </div>
                                    <div className={'col-md-6 text-justify'}>
                                        <StaticImage src={"../../gatsbyimg/docs/autospreading_1.png"} alt={"Auto-spreading order"} className={"border pt-10"}/>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <h3>Advanced options</h3>
                                        <ul className="list-style1 pt-10">
                                            <li><b>Quoting on</b> - on which market limit orders will be placed with a price details of the market</li>
                                            <li><b>VWAP Multiplier</b> - multiplier on size used to deduce the price - see below</li>
                                            <li><b>Cooldown (ms)</b> - minimum age of the limit order to update it</li>
                                            <li><b>Max Order Move</b> - limits order movement in one action, withdrawing the working order and cancelling the spread if exceeded.</li>
                                            <li><b>Slop</b> - precision of order adjustment - see below</li>
                                            <li><b>Iceberg order</b> - divide large order into smaller limit orders - see below </li>
                                            <li><b>Heging type</b> - strategy for the heging order - see below</li>
                                        </ul>
                                        <br/><br/>
                                    </div>
                                    <div className={'col-md-6 text-justify'}>
                                        <StaticImage src={"../../gatsbyimg/docs/autospreading_2.png"} alt={"Auto-spreading order"} className={"border pt-10"}/>
                                    </div>
                                </div>


                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <p>
                                            Active-Spreading is the most advanced type of spread orders provided by BitSpreader. Active-spreading is using the combination of limit and market orders to
                                            execute the spread transaction. One of the spread legs is executed using the limit order and second with market order.
                                            <br/><br/>
                                            BitSpreader places and actively updates limit orders on the market selected as the "Quoting" market. The second market in the spread is called "Hedging"
                                            market.
                                            As soon as the limit order is filled, BitSpreader executes the market (taker) order on the hedging market. This way of execution
                                            is <b>faster</b> and <b>cheaper</b> compared to Auto-Trigger orders. Users have higher chance to catch short-lasting spreads and pay lower fees - instead of
                                            4 times the taker order fee as in the full cycle of auto-trigger/manual spread orders - the fees that need to be paid are 2 times taker fee and 2 times
                                            maker fee. In some cases user may get a rebate instead of paying the fee (the cost of executing the market order and providing the liquidity to the exchange
                                            is negative - the exchange pays to the user for his service).

                                            <br/><br/><b>How is the limit order calculated/updated</b>


                                            <ul className="list-style1 pt-10 pb-10">
                                                <li>Limit order price is calculated as the hedging market price plus spread limit price (or minus depending on the direction and which one is quoting
                                                    which hedging).
                                                </li>
                                                <li>When the price on the hedging exchange moves, BitSpreader recalculates the price on the limit order and updates it</li>
                                                <li>
                                                    Example:
                                                    <ul className="list-style1">
                                                        <li>user wants to buy the spread Binance:BTC-PERP - Deribit:BTC-Mar23, BitSpreader needs to buy BTC-PERP on Binance and sell BTC-Mar23 on
                                                            Deribit.
                                                        </li>
                                                        <li>User has selected to buy the spread with the Auto-Spreading order, user provides the spread limit price: 30$ and Binance:BTC-PERP as the
                                                            quoting market. Deribit:BTC-Mar23 will be the hedging market
                                                        </li>
                                                        <li>BitSpreader takes the current price on the selling market - Deribit:BTC-Mar23 BID - ie 17615$, computes the limit order price by subtracting
                                                            the limit spread price (30$) - 17615$ - 30$ = 17585$ - the limit order price is 17585$
                                                        </li>
                                                        <li>BitSpreader places the limit order on the quoting exchange: BUY Binance:BTC-PERP with price 17585$</li>
                                                        <li>The price on the hedging exchange - Deribit:BTC-Mar23 BID - moves from 17615$ to 17600$, BitSpreader recalculates the new limit price -
                                                            it is 17600$ - 30$ = 17570$ and updates the limit order: BUY Binance:BTC-PERP with price 17570$
                                                        </li>
                                                        <li>The limit order is filled - BitSpreader instantly executes the market order: SELL on Deribit:BTC-Mar23.
                                                            Assuming the price didn't move in the meantime this order should be instantly filled with price 17600$
                                                        </li>
                                                        <li>This user has bought the spread Binance:BTC-PERP - Deribit:BTC-Mar23 with the price 17600$ - 17570$ = 30$</li>
                                                    </ul>
                                                </li>
                                                <li>To reduce the flickering effect on the limit order (continuous placing the order and cancelling it instantly) we have following controls
                                                    <ul className="list-style1">
                                                        <li>If the difference between newly computed spread between current hedging price and existing order limit price is greater than <b>SLOP</b>,
                                                            then
                                                            the
                                                            existing order should be updated with the new price (unless existing order is younger than cooldown time)
                                                            - this is called symmetric slop. Asymmetric slop has two parameters: <b>inside slop</b> (number of ticks) - representing the worst price user can accept for the spread
                                                            and <b>outside slop</b> (number of ticks) - representing the best price user can accept for the spread
                                                        </li>
                                                        <li>In order to assess the hedging price, BitSpreader looks into hedging exchange order book and computes the VWAP (volume weighted average
                                                            price)
                                                            of the expected order size multiplied by <b>VWAP multiplier</b>. The bigger the VWAP multiplier, the more conservative will be the price
                                                            calculated
                                                            in this way (VWAP calculation will be reaching deeper into the order book and the price will reach levels that are less likely to get filled
                                                            in
                                                            short
                                                            time) therefore providing more stable price assessment.
                                                        </li>
                                                        <li>In case the new limit order price calculation matches the SLOP condition, BitSpreader will update the existing limit order only in case it
                                                            is
                                                            older than cooldown time. In other words, cooldown time defines the minimum age (in milliseconds) of the limit order after which BitSpreader
                                                            will attempt to update it.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>To maintain market stability and preserve order integrity, users can utilize the <b>Max Order Move</b> parameter in BitSpreader. This feature is designed to prevent excessive or abrupt movements in quoting orders, acting as a protective measure against large and sudden shifts in order positions. By setting a threshold for how far an order can move in a single action, it ensures that trades are executed within reasonable and anticipated limits. If this parameter is triggered, BitSpreader will immediately cancel the spread order and transition to an ERROR state, providing additional details about the 'Max Order Move' breach.
                                                </li>
                                                <li>Iceberg order allows to execute a large order by placing smaller (defined as "<b>Iceberg display size</b>" param) orders. When one disclosed portion is filled, then the next portion in sent to the market after delay defined as <b>refill delay</b> param.</li>
                                                <li>Fees are not taken under account in the limit order price calculations, actual fees are displayed on spread positions and taken into account in the
                                                    spread position profit and loss calculation.
                                                </li>
                                                <li>There are certain conditions in which the calculated limit order price would be instantly matched as a taker order. BitSpreder takes following steps
                                                    to avoid that:
                                                    <ul>
                                                        <li>BitSpreader is using POST-only flag on the limit orders. Post-only Mode means that Traders can only place an Order if it would be posted to
                                                            the
                                                            Order Book as a Maker Order.
                                                        </li>
                                                        <li>
                                                            In case BitSpreader has received the price updates that show that computed limit order price would be instantly filled, the BitSpreader
                                                            updates the price to put the limit order on the top of the order book.
                                                        </li>
                                                    </ul>
                                                </li>

                                            </ul>

                                            <br/><b>Hedging</b>
                                            <ul className="list-style1 pt-10 pb-10">
                                                <li>Hedging with market orders - it guarantees the order will be filled but it can be filled with a price that is not optimal - the best available current price</li>

                                                <li>Limit IOC with payup ticks - By using this strategy, traders ensure they do not pay more than a certain amount (or sell for less than a certain amount). The payup ticks offer a degree of flexibility to account for market volatility, increasing the likelihood of the order being executed while still controlling the slippage (the difference between the expected price of a trade and the executed price).</li>
                                            </ul>
                                        </p>

                                        <br/>
                                        <b>IMPORTANT!</b> There is no guarantee that the actual price of the spread will be equal to the spread limit price - there is probability
                                        of <b>SLIPPAGE</b> due to the <b>LATENCY</b>.

                                        <br/><br/><b>After the Auto-Spreading order is created: </b> you can manage the order in the <a href="#activespreadorders" className={"read-more-link"}>Active
                                        Spread Orders</a> pane

                                        <br/><br/><b>Restrictions and Validation: </b> please refer to the <a href="#spreaedmarketdetails" className={"read-more-link"}>Spread Market
                                        Details</a> section for reference

                                    </div>

                                </div>

                                <br/><br/><a name={"spreadmarketdetails"}><h4 className={"pt-20 fw-bold"}>Spread Market Details</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '30%'}}>Field</th>
                                                <th style={{width: '70%'}}>Comments</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th style={{width: '30%'}}>Amount step size</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Amount step size is the minimum increment allowed when buying or selling this instrument
                                                    <br/><br/><i>amtStepSize = minimal common denominator(leg1AmtStepSize, leg2AmtStepSize)</i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>Contract size</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Contract size refers to the amount or quantity of an underlying security represented by a derivatives contract usually equal to amount step size.
                                                    <br/><br/><i>ctrSize = minimal common denominator(leg1ctrSize, leg2ctrSize)</i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>Minimum notional value</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Minimum notional value expresses minimum value of the contract, below which the exchange will reject the order
                                                    <br/><br/><i>minNotionalValue = max(leg1minNotionalValue, leg2minNotionalValue)</i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>Minimal trade size</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Minimum trade size expressed in base contract currency
                                                    <br/><br/><i>minTradeSize = max(leg1minTradeSize, leg2minTradeSize)</i>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <a name={"activespreadorders"}><h4 className={"pt-20 fw-bold"}>Active Spread Orders</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <StaticImage src={"../../gatsbyimg/docs/activespreadorders.png"} alt={"Active spread orders"} className={"border pt-10"}/>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        All auto-trigger and active spreading spread orders that have not been fully realised yet are visible in the "Active Spread Orders" pane.
                                        <br/>You can <b>cancel</b> the order as long as it is not fully filled.

                                        <br/><br/>
                                        <b>Spread order states</b>:
                                        <br/><br/>
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th style={{width: '30%'}}>Spread order state</th>
                                                <th style={{width: '70%'}}>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th style={{width: '30%'}}>WORKING</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    BitSpreader is waiting for the trigger price to execute the order
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>REJECTED</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    Both legs of the spread order have been rejected by the exchange
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>CANCELLED</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    User has cancelled the spread order
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>FILLED</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    The spread order is fully filled
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>PARTIALLY_FILLED</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    The spread order is filled only partially, both legs are equal, there is no exposure
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{width: '30%'}}>INCOMPLETE</th>
                                                <td style={{width: '70%'}} className={'text-justify'}>
                                                    The spread order is filled only partially the fill on one leg is not equal to the fill on the second leg - exposure is present.
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                        <br/><br/>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default Fst;
